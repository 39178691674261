import React, { useState, useEffect } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import moment from 'moment';

import Asset from '@src/components/Asset';
import Button from '@src/components/buttons/Button';

export default function EventsList() {
    const {
        pages: { nodes: pages },
    } = useStaticQuery(
        graphql`
            {
                pages: allCraftProjectsAndEventsDefaultEntry(
                    filter: {
                        enabled: { eq: true }
                        level: { gte: 2 }
                        projectAndEventTags: { elemMatch: { title: { eq: "Festival" } } }
                    }
                    sort: { fields: eventDate, order: DESC }
                ) {
                    nodes {
                        remoteId
                        typeHandle
                        level
                        uri
                        ... on Craft_projectsAndEvents_default_Entry {
                            title
                            url
                            eventDate
                            eventEndDate
                            plainText
                            projectAndEventTags {
                                title
                            }
                            singleImage {
                                ... on Craft_images_Asset {
                                    url
                                    title
                                    imageFile {
                                        childImageSharp {
                                            gatsbyImageData(layout: CONSTRAINED)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    );

    const projects = pages;
    const upcomingProjects = projects
        .filter(obj => {
            const endDate = obj.eventEndDate ? obj.eventEndDate : obj.eventDate;
            return moment(endDate) > moment();
        })
        .sort((a, b) => new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime());
    const years = [];
    const hasUpcomingEvents = upcomingProjects.length > 0;

    const [when, setWhen] = useState(hasUpcomingEvents ? 'Upcoming' : 'All');
    const [list, setList] = useState(
        hasUpcomingEvents ? [...upcomingProjects.slice(0, 9)] : [...projects.slice(0, 9)]
    );
    const [loadMore, setLoadMore] = useState(false);
    const [hasMore, setHasMore] = useState(upcomingProjects.length > 9);

    const handleTime = e => {
        setWhen(e.target.value);
    };

    const handleLoadMore = () => {
        setLoadMore(true);
    };

    useEffect(() => {
        if (when === 'Upcoming') {
            setList([...upcomingProjects.slice(0, 9)]);
        } else if (when === 'All') {
            setList([...projects.slice(0, 9)]);
        } else {
            setList([
                ...projects.filter(obj => moment(obj.eventDate).format('Y') === when).slice(0, 9),
            ]);
        }
    }, [when]);

    useEffect(() => {
        if (loadMore && hasMore) {
            const currentLength = list.length;
            if (when === 'All') {
                const isMore = currentLength < projects.length;
                const nextResults = isMore ? projects.slice(currentLength, currentLength + 9) : [];
                setList([...list, ...nextResults]);
            } else if (when === 'Upcoming') {
                const isMore = currentLength < upcomingProjects.length;
                const nextResults = isMore
                    ? upcomingProjects.slice(currentLength, currentLength + 9)
                    : [];
                setList([...list, ...nextResults]);
            } else {
                const filteredPosts = projects.filter(
                    obj => moment(obj.eventDate).format('Y') === when
                );
                const isMore = currentLength < filteredPosts.length;
                const nextResults = isMore
                    ? filteredPosts.slice(currentLength, currentLength + 9)
                    : [];
                setList([...list, ...nextResults]);
            }
            setLoadMore(false);
        }
    }, [list, loadMore, hasMore]);

    useEffect(() => {
        if (when === 'Upcoming') {
            if (list.length < upcomingProjects.length) {
                setHasMore(true);
            } else {
                setHasMore(false);
            }
        } else if (when !== 'Upcoming') {
            if (list.length < projects.length) {
                setHasMore(true);
            } else {
                setHasMore(false);
            }
        }
    }, [list, when]);

    const postsEl = list.map((entry, i) => {
        const date = moment(entry.eventDate).format('MMM D, Y');
        const endDate = entry.eventEndDate && moment(entry.eventEndDate).format('MMM D, Y');
        const location = entry.plainText;
        return (
            <li key={`${entry.title + i}`} className="relative list-style-none list-wrapper">
                <Link to={`/${entry.uri}`}>
                    {entry.singleImage[0] ? (
                        <div className="mb-24px">
                            <Asset
                                border
                                data={entry.singleImage[0].imageFile}
                                title={entry.singleImage[0].title}
                            />
                        </div>
                    ) : null}

                    <h3 className="h3 mb-8px">{entry.title}</h3>
                    {date && (
                        <p>
                            {date}
                            {endDate && <span>–{endDate}</span>}
                        </p>
                    )}
                    {location && <p>{location}</p>}
                </Link>
            </li>
        );
    });

    let yearsOptions = projects.forEach(project => {
        const year = moment(project.eventDate).format('Y');
        if (!years.includes(year)) {
            years.push(year);
        }
        return years;
    });
    yearsOptions = years.map(year => (
        <option key={`year-${year}`} value={year}>
            {year}
        </option>
    ));

    return (
        <div>
            <div className="flex">
                <div style={{ marginRight: 36 }} className="flex flex-wrap items-center mb-32px">
                    <h4 style={{ marginRight: 8 }} className="h4 m-0">
                        When:
                    </h4>
                    <div className="flex relative arrow-down">
                        <select
                            onChange={handleTime}
                            className="button button--select h4 m-0"
                            defaultValue={hasUpcomingEvents ? 'Upcoming' : 'All'}>
                            <option value="Upcoming">Upcoming</option>
                            <option value="All">All</option>
                            {yearsOptions}
                        </select>
                    </div>
                </div>
            </div>

            <div className="grid grid--3 mb-64px">
                {postsEl.length > 0 ? postsEl : <p>No upcoming events</p>}
            </div>
            {hasMore ? (
                <Button
                    large
                    classes="button--full mb-16px"
                    copy="Load More"
                    onClick={handleLoadMore}
                />
            ) : null}
        </div>
    );
}
