import * as React from 'react';
import { graphql } from 'gatsby';

// components
import EventsList from '@src/components/EventsList';
import Seo from '@src/components/SEO';

// utils
import { renderSwitch } from '@src/utils/Render';

export const query = graphql`
    {
        page: craftPoetryFestivalPoetryFestivalEntry {
            id: remoteId
            title
            typeHandle
            seo {
                title
                description
                keywords {
                    keyword
                }
                advanced {
                    robots
                    canonical
                }
                social {
                    twitter {
                        image {
                            ... on Craft_images_Asset {
                                id
                                url
                            }
                        }
                    }
                    facebook {
                        image {
                            ... on Craft_images_Asset {
                                id
                                url
                            }
                        }
                    }
                }
            }
            contentMatrix {
                ... on Craft_contentMatrix_richTextBlock_BlockType {
                    initialText
                    richText
                    typeHandle
                    actsAsHeader
                    linkToEntry {
                        title
                        url
                        uri
                    }
                }
                ... on Craft_contentMatrix_excerptBlock_BlockType {
                    excerpt
                    typeHandle
                }
                ... on Craft_contentMatrix_productsBlock_BlockType {
                    typeHandle
                    related
                    productsList {
                        ... on Craft_shop_product_Entry {
                            id: remoteId
                            title
                            uri
                            typeHandle
                            plainText
                            multipleImages {
                                url
                                title
                                ... on Craft_images_Asset {
                                    url
                                    title
                                    imageFile {
                                        childImageSharp {
                                            gatsbyImageData(layout: CONSTRAINED)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                ... on Craft_contentMatrix_relatedEventsBlock_BlockType {
                    typeHandle
                    eventsList {
                        ... on Craft_projectsAndEvents_default_Entry {
                            id: remoteId
                            title
                            uri
                            typeHandle
                            eventDate
                            plainText
                            singleImage {
                                url
                                title
                                ... on Craft_images_Asset {
                                    url
                                    title
                                    imageFile {
                                        childImageSharp {
                                            gatsbyImageData(layout: CONSTRAINED)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                ... on Craft_contentMatrix_textAndImageBlock_BlockType {
                    blockCopy
                    blockImage {
                        ... on Craft_images_Asset {
                            imageFile {
                                childImageSharp {
                                    gatsbyImageData(layout: CONSTRAINED)
                                }
                            }
                            url
                            title
                        }
                    }
                    typeHandle
                }
                ... on Craft_contentMatrix_singleMedia_BlockType {
                    singleAsset {
                        ... on Craft_images_Asset {
                            url
                            title
                            imageFile {
                                childImageSharp {
                                    gatsbyImageData(layout: CONSTRAINED)
                                }
                            }
                        }
                    }
                    typeHandle
                }
                ... on Craft_contentMatrix_twoUpImageBlock_BlockType {
                    typeHandle
                    assets {
                        ... on Craft_images_Asset {
                            imageFile {
                                childImageSharp {
                                    gatsbyImageData(layout: CONSTRAINED)
                                }
                            }
                            url
                            title
                        }
                    }
                }
                ... on Craft_contentMatrix_imageCarousel_BlockType {
                    typeHandle
                    carouselImages {
                        ... on Craft_images_Asset {
                            url
                            title
                            imageFile {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                    }
                }
                ... on Craft_contentMatrix_linkBlocks_BlockType {
                    typeHandle
                    linkItem {
                        ... on Craft_linkItem_BlockType {
                            richText
                            buttonCopy
                            buttonUrl
                        }
                    }
                }
                ... on Craft_contentMatrix_accordionBlock_BlockType {
                    typeHandle
                    accordionItem {
                        ... on Craft_accordionItem_BlockType {
                            accordionLabel
                            accordionContent
                        }
                    }
                }
                ... on Craft_contentMatrix_projectList_BlockType {
                    showEvents
                    typeHandle
                }
                ... on Craft_contentMatrix_newsletterSignup_BlockType {
                    showNewsletter
                    typeHandle
                }
                ... on Craft_contentMatrix_giveCloudForm_BlockType {
                    typeHandle
                    showForm
                }
                ... on Craft_contentMatrix_donationForm_BlockType {
                    typeHandle
                    showForm
                }
                ... on Craft_contentMatrix_dynamicButton_BlockType {
                    typeHandle
                    buttonCopy
                    buttonLink
                    position
                }
                ... on Craft_contentMatrix_videosEmbedBlock_BlockType {
                    typeHandle
                    videoLinks {
                        ... on Craft_videoLinks_BlockType {
                            videoLink
                        }
                    }
                }
            }
        }
    }
`;

const IndexPage = ({ data: { page }, location }) => {
    const { seo } = page;
    const keywords = [];
    if (seo.keywords) {
        seo.keywords.forEach(keyword => {
            keywords.push(keyword.keyword);
        });
    }
    const content = page.contentMatrix;

    return (
        <>
            <Seo
                title={seo.title}
                canonical={seo.advanced.canonical}
                description={seo.description}
                facebookImage={seo.social.facebook.image?.url}
                keywords={keywords}
                robots={seo.advanced.robots}
                twitterImage={seo.social.twitter.image?.url}
                modifiedTime={page.dateUpdated}
                url={location.href}
                landing
            />
            <div>{content.map((block, i) => renderSwitch(block, i))}</div>
            <EventsList />
        </>
    );
};

export default IndexPage;
